import 'regenerator-runtime';
import { yiluEnv, renderYiluApp, routes } from '@yiluhub/travel-integration';

import { overrides } from './overrides/map';
import { iconOverrides } from './overrides/icons';

import './styles/index.scss';

yiluEnv.setVariables({
  YILU_API_TOKEN: import.meta.env.VITE_YILU_API_TOKEN,
  YILU_API_PRODUCTION_ENVIRONMENT: import.meta.env.VITE_YILU_API_PRODUCTION_ENVIRONMENT,
  GOOGLE_MAPS_KEY: import.meta.env.VITE_GOOGLE_MAPS_KEY,
  GOOGLE_ANALYTICS_MEASUREMENT_ID: import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID,
  GOOGLE_TAG_MANAGER_CONTAINER_ID: import.meta.env.VITE_GOOGLE_TAG_MANAGER_CONTAINER_ID,
  HOTJAR_ID: import.meta.env.VITE_HOTJAR_ID,
  CORAS_PARTNER_ID: import.meta.env.VITE_CORAS_PARTNER_ID,
  LOCALES_HOST_URL: import.meta.env.VITE_LOCALES_HOST_URL,
  PHONE_COUNTRY_CODE: import.meta.env.VITE_PHONE_COUNTRY_CODE,
  YILU_ENTERPRISE_PARTNER: import.meta.env.VITE_YILU_ENTERPRISE_PARTNER,
  FAQ_TYPE_FORM_EN: 'GEmH7eFC',
  FAQ_TYPE_FORM_DE: 'AyGIuidV',
  YILU_SHOW_PARK_AERO_BCP_PRINT_BUTTON: import.meta.env.VITE_YILU_SHOW_PARK_AERO_BCP_PRINT_BUTTON,
  YILU_BACKEND_URL: import.meta.env.VITE_YILU_BACKEND_URL,
  YILU_LANDING_PAGE_ENABLED: import.meta.env.VITE_YILU_APP_YILU_LANDING_PAGE_ENABLED,
  // AMP envs
  YILU_AMP_BACKEND_URL: import.meta.env.VITE_YILU_AMP_BACKEND_URL,
  YILU_AMP_STORE_ID: import.meta.env.VITE_YILU_AMP_STORE_ID,
  YILU_AMP_LOUNGE_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_LOUNGE_VERTICAL_ID,
  YILU_AMP_PARKING_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_PARKING_VERTICAL_ID,
  YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID,
  YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID: import.meta.env
    .VITE_YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID,
  YILU_AMP_RIDES_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_RIDES_VERTICAL_ID,
  YILU_AMP_CAR_RENTAL_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_CAR_RENTAL_VERTICAL_ID,
  YILU_AMP_LOUNGE_CATALOG_ID: import.meta.env.VITE_YILU_AMP_LOUNGE_CATALOG_ID,
  YILU_AMP_MEILI_CATALOG_ID: import.meta.env.VITE_YILU_AMP_MEILI_CATALOG_ID,
  // Highpass envs
  HIGHPASS_API_KEY: import.meta.env.VITE_HIGHPASS_API_KEY,
  HIGHPASS_STYLES: {
    backgroundColor: '#ffffff',
    buttonColor: '#1E73AF',
    inputColor: '#F8F9FD',
    linkColor: '#1E73AF',
    headingColor: '#1E73AF',
    defaultTextColor: '#2d2d2d',
    errorColor: '#FF0000',
    serviceDescriptionColor: '#2d2d2d',
    labelColor: '#333333',
    importantColor: '#2d2d2d',
    headerTextColor: '#2d2d2d',
    descriptionTextColor: '#2d2d2d',
  },
});

renderYiluApp({
  overrides,
  iconOverrides,
  defaultPath: routes.LOUNGES_SEARCH,
  assetPathOverrides: {
    parking: {
      sipImagePaths: [
        'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/parking/discover-airlines/parking.jpg',
      ],
    },
    lounges: {
      sipImagePaths: [
        'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/lounges/discover-airlines/lounge.jpg',
      ],
    },
    airportTransfer: {
      sipImagePaths: [
        'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/airportTransfers/discover-airlines/airport-transfer.jpg',
      ],
    },
    rides: {
      sipImagePaths: [
        'https://provider-resources-production-v2.s3.eu-central-1.amazonaws.com/rides/discover-airlines/rides.jpg',
      ],
    },
  },
});
